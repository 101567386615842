<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="is_used" slot-scope="text, record">
          <a-switch @change="handleUsed(record)" :checked="text"/>
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">编辑</a>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-delivery-address-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { createDeliveryAddress, putDeliveryAddress, deliveryAddressList } from '@/api/applet_shopmall_delivery_address'
import CreateDeliveryAddressForm from '@/views/a-applet/shop_manage/deliveryaddress/CreateDeliveryAddressForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateDeliveryAddressForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          ellipsis: true,
          dataIndex: 'warehouse'
        },
        {
          title: '自提点名称',
          ellipsis: true,
          dataIndex: 'name'
        },
        {
          title: '省市区',
          ellipsis: true,
          dataIndex: 'province',
          customRender: (text, record, index) => record.province + ',' + record.city + ',' + record.county
        },
        {
          title: '详细地址',
          ellipsis: true,
          dataIndex: 'address'
        },
        {
          title: '电话',
          ellipsis: true,
          dataIndex: 'phone'
        },
        {
          title: '启用',
          ellipsis: true,
          dataIndex: 'is_used',
          scopedSlots: { customRender: 'is_used' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return deliveryAddressList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleCreate () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
      // this.$router.push({ name: 'CreateDeliveryAddress' })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          if (this.mdl && this.mdl.id) {
            putDeliveryAddress(values, this.mdl.id).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            console.log('提交的数据----', values)
            createDeliveryAddress(values).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUsed (item) {
      console.log(item)
      this.loading = true
      putDeliveryAddress({ is_used: !item.is_used }, item.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleEdit (item) {
      console.log(item)
      this.mdl = item
      this.visible = true
      this.confirmLoading = false
    }
  }
}
</script>
