<template>
  <a-modal
    title="新建自提点"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="仓库" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                show-search
                allow-clear
                v-decorator="[
                  'warehouse_code',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入仓库名称"
                @search="this.warehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.code" :value="d.code">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['name', {rules: [{ required: true, message: '请输入' }]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="省" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['province', {rules: [{required: true, message: '请输入'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="电话" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['phone', {rules: [{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="市" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['city', {rules: [{required: true, message: '请输入'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="纬度" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input style="width: 70%" v-decorator="['latitude', {rules: [{required: true, message: '请输入'}]}]"/>
              <a href="https://api.map.baidu.com/lbsapi/getpoint/index.html" target="_blank" style="margin-left: 30px;">拾取坐标</a>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="区" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['county', {rules: [{required: true, message: '请输入'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="经度" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input style="width: 70%" v-decorator="['longitude', {rules: [{required: true, message: '请输入'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="详址" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['address', {rules: [{required: true, message: '请输入'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item label="邮政编码" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['area_code', {rules: [{required: true, message: '请输入'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="启用" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-switch v-decorator="['is_used', { initialValue: true, valuePropName: 'checked' }]"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import debounce from 'lodash/debounce'

const fields = ['warehouse_code', 'phone', 'name', 'province', 'city', 'county', 'address', 'longitude', 'latitude', 'is_used']
export default {
  name: 'DeliverAddressForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      warehouse_fetching: false,
      dataSource_wms_warehouse: [],
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadWarehouse({ code: this.model.warehouse_code })
        this.loadEditInfo(this.model)
      } else {
        this.handleWarehouseSearch(undefined)
      }
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
      // console.log('------------->>>>', data)
      // this.map.province = data.province
      // this.map.city = data.city
      // this.map.county = data.county
      // this.map.adcode = data.adcode
      // this.map.latitude = data.latitude
      // this.map.longitude = data.longitude
      // if (!this.map.city) {
      //   this.map.city = this.map.province
      // }
      // this.map.map_address = this.map_address
    },
    mapData (data) {
      const province = data.regeocode.addressComponent.province
      const city = data.regeocode.addressComponent.city === '' ? province : data.regeocode.addressComponent.city
      const district = data.regeocode.addressComponent.district
      const adcode = data.regeocode.addressComponent.adcode
      this.map.province = province
      this.map.city = city
      this.map.county = district
      this.map.adcode = adcode
      this.map.latitude = data.latitude
      this.map.longitude = data.longitude
      if (this.map.city.length === 0) {
        this.map.city = this.map.province
      }
      const map_address = this.map.province + ',' + this.map.city + ',' + this.map.county
      const { form } = this
      form.setFieldsValue({ map_address: map_address })
    },
    handleWarehouseSearch (obj) {
      this.loadWarehouse({ name: obj })
    },
    loadWarehouse (obj) {
      this.dataSource_wms_warehouse = []
      this.warehouse_fetching = true
      warehouse_list(obj).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWmsWarehouseChange (value) {
      console.log(value)
      if (value === undefined) {
        this.loadWarehouse({ name: value })
      }
    }
  }
}
</script>
<style>
</style>
