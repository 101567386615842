import request from '@/utils/request'

export function deliveryAddressList (query) {
  return request({
    url: '/shop/back_pick_up_address/',
    method: 'get',
    params: query
  })
}
export function createDeliveryAddress (parameter) {
  return request({
    url: '/shop/back_pick_up_address/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function putDeliveryAddress (parameter, id) {
  return request({
    url: '/shop/back_pick_up_address/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteDeliveryAddress (id) {
  return request({
    url: '/shop/back_pick_up_address/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
